<template>
  <div class="list-wrapper">
    <div class="head_image">
      <img class="_back" @click="$router.go(-1)" src="https://pic.eaworks.cn/chenghua/btn_back.png" alt="">
      <img class="image_item"  src="https://pic.eaworks.cn/chenghua/flyh.png" alt="">
    </div>
    <div class="md_box">
      <div class="top">
        <div class="_list">
          <div class="_item" v-for=" item of typeList1" @click="onClickItem(item)"  >
            <img :src="item.resources_img" alt=""/>
          </div>
          <div class="_item" v-for="item of typeList2" @click="onClickItem(item)">
            <img :src="item.resources_img" alt=""/>
          </div>
        </div>

      </div>
    </div>
    <div class="back_top" v-if="scrollTop >= 400">
      <img src="https://pic.eaworks.cn/chenghua/go_top.png" alt="" @click="onClickBackTop" />
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      typeList1: [],
      typeList2: [],
      scrollTop: 0
    };
  },

  created() {
    console.log("-> [card.vue] created");

  },

  mounted() {
    console.log("-> [card.vue] mounted");
    this.ajaxGetListType1Data();
    this.ajaxGetListType2Data();

    window.addEventListener("scroll", () => {
      this.pageScroll();
    })
  },
  methods: {
    pageScroll() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    },
    onClickItem(item) {
      if (item.link_url !== "") {
        this.$router.push(item.link_url)
      }
    },

    async ajaxGetListType1Data() {
      // 获取指定标签的图片资源
      let {data} = await this.$ajax.gateway("/api/api_get_resource_by_label", {
        "METHOD": "get",
        "label_title": "送菜"
      });
      this.typeList1 = data;
    },
    async ajaxGetListType2Data() {
      // 获取指定标签的图片资源
      let {data} = await this.$ajax.gateway("/api/api_get_resource_by_label", {
        "METHOD": "get",
        "label_title": "优惠"
      });
      this.typeList2 = data;
    },

    onClickBackTop() {
      scrollTo(0,0);
    }
  }
};
</script>

<style lang="less" scoped>
.head_image {
  position: fixed;
  top: 0;
  left: 0;
  .image_item{
    width: 100%;
  }
}

.title_img {
  width: 100%;
}

.md_box {
  margin: 0 auto;
  text-align: center;
  .top {
    margin: 0 auto;
    .head_jj {
      width: 80%;
    }
  }

  .no_list_img {
    margin-top: 3rem;
  }
}

.list-wrapper {
  background: linear-gradient(to top,  #fdffeb, #d5f597);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ._back {
    height: 0.9rem;
    width: 1.8rem;
    position: fixed;
    top: 0.4rem;
    left: 0;
    z-index: 10;
  }

  ._list {
    margin-top: 5rem;
    padding-bottom: 2rem;
    ._item {
      margin: 0 auto 0 auto;
      font-size: 0.4rem;
      padding: 0.2rem 0;
      font-weight: 600;
      img {
        width: 80%;
      }
    }
  }
}
.back_top {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0;
  text-align: center;
  background: #80a44b;
  -webkit-animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  img {
    display: block;
    width: 4rem;
    margin: 0 auto;
  }
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>
